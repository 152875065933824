<template>
  <!--  todo: рефактор кода под Base (сделать возможным поместить сюда "любую таблицу" вместо "насаждения") -->
  <v-card
    class="mt-1"
    outlined
    tile
  >
    <div class="d-flex justify-lg-space-between">
      <v-card-title bold>Список насаждений паспорта {{ passport.properties.object_name }}</v-card-title>
      <div class="d-flex justify-lg-space-between">
        <BaseBtn
          class="ma-3"
          small
          width="205px"
          color="primary"
          @click="dialog = !dialog"
          :disabled="disabled"
          :title="'Выбрать насаждения для ПБ'"
        />
      </div>
    </div>
    <NasajdeniyaList
      v-model="localValue"
      :passport="passport"
      :headers="headers"
      :entity-id="nasajdeniyaId"
      :dialog.sync="dialog"
      @input="handleUpdateValue(localValue)"
      :class-table="classTable"
    />
    <v-card-text>
      <NasajdeniyaForReportList
        v-model="localValue"
        :headers="headers"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import BaseBtn from '@/components/base/UI/BaseBtn'
import NasajdeniyaList from '@/components/views/account/fellingTicket/detail/detailView/NasajdeniyaList'
import NasajdeniyaForReportList
  from '@/components/views/account/fellingTicket/detail/detailView/NasajdeniyaForReportList'
import BaseReportPanel from '@/components/base/BaseReportPanel'
import { cleanClone, flatenObject, sortWithNull } from '@/components/utils/common'

export default {
  name: 'BaseDialogTableSelect',
  components: { NasajdeniyaForReportList, NasajdeniyaList, BaseBtn, BaseReportPanel },
  props: {
    value: Array,
    passport: Object,
    disabled: Boolean,
    classTable: String
  },
  data () {
    return {
      dialog: false,
      localValue: [],
      nasajdeniyaId: 41
    }
  },
  computed: {
    headers () {
      if (this.passport && Object.keys(this.passport).length) {
        return this.objectAttributesFlat.filter(item => item.show_in_table).sort(sortWithNull('order')).map(item => ({
            text: item.label,
            value: item.name,
            optionCategoryId: item.optionCategoryId,
            type: item.eavType.name,
            align: 'start',
            sortable: false,
            showTooltip: !!this.getLabelGroup(item),
            tooltipText: item.parent_id && this.getLabelGroup(item)
          })
        )
      }
    },
    passportDetail () {
      return this.passport.properties
    },
    eavEntity () {
      return cleanClone(this.passportDetail.passportEntities.find(item => item.eavEntity.id === this.nasajdeniyaId))
    },
    objectAttributesFlat () {
      return this.eavEntity.eavEntity.baseAttributes.concat(flatenObject(this.eavEntity.eavEntity.eavAttributes, 'relatedAttributes'))
    }
  },
  methods: {
    getLabelGroup (item) {
      let result = ''
      const parentItem = this.objectAttributesFlat.find((element) => element.id === item.parent_id)
      if (parentItem?.eavType.id === 14) {
        result = 'Группа: ' + parentItem.label + '. \r\n \n ' + 'Атрибут: ' + item.label
      }
      return result
    },
    handleUpdateValue (value) {
      let localValue = cleanClone(this.value)
      localValue = value
      this.$emit('input', localValue)
    }
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>

</style>