<template>
  <BaseDetail
    v-if="detail"
    :is-loading="loading"
  >
    <template #header>
      <BaseHeader
        @save="save"
        @delete="handleDelete"
        @close="handleClose"
        :save-btn="!detail.id"
      />
    </template>
    <template #content>
      <DetailView
        v-if="detail"
        v-model="detail"
        ref="content"
      />
    </template>
  </BaseDetail>
</template>

<script>
import detail from '@/components/mixins/detail'
import BaseDetail from '@/components/base/BaseDetail'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import DetailView from './detail/DetailView'

export default {
  mixins: [detail],
  name: 'Detail',
  components: { BaseHeader, BaseDetail, DetailView },
  computed: {
    controllerName () {
      return 'request-felling-ticket'
    }
  },
  methods: {
    handleClose () {
      this.$router.push({ name: 'requestFellingTicket' })
    }
  }
}
</script>

<style scoped>

</style>
