<template>
  <BaseAutocomplete
    :prepend-inner-icon="'mdi-magnify'"
    v-model="region"
    item-value="id"
    item-text="fullLabel"
    :label="label || $t('region.nameRegion')"
    :url="url"
    :chars-to-search="3"
    clearable
    outlined
    dense
    hide-details
    color="secondary"
    :params="params"
    :disabled="disabled"
  />
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete'
import _ from 'lodash'

export default {
  name: 'BaseRegionSelect',
  components: { BaseAutocomplete },
  props: {
    leafsOnly: {
      default: false,
      type: Boolean
    },
    label: String,
    //note: Вика произвел небольшой рефактор, теперь value это regionId или объект region
    value: [Number, Object],
    //note: если включаем этот флаг, то возвращаем объект региона (детали паспорта)
    returnObject: {
      default: false,
      type: Boolean
    },
    disabled: Boolean
  },
  data () {
    return {
      url: '/region/autocomplete'
    }
  },
  computed: {
    region: {
      get () {
        return this.checkFieldRoute(this.value)
      },
      set (val) {
        //note: Вика передаем undefined вместо null, чтобы убрать из get параметров regionId (c null это будет выглядеть как regionId= )
        let returnValue
        if (val) {
          returnValue = this.returnObject ? val : val.id
        } else {
          returnValue = this.returnObject ? null : undefined
        }
        this.$emit('input', returnValue)
      }
    },
    params () {
      return this.leafsOnly ? { is_leaf: 1 } : {}
    }
  },
  methods: {
    checkFieldRoute (region) {
      let currentRegion = {}
      let localRegionId
      // получаем regionId тем или иным способом
      if (region) {
        if (_.isObjectLike(region)) {
          currentRegion = region
        } else if (_.isNumber(region)) {
          localRegionId = region
        }
      } else if (Object.keys(this.$route?.query).length && this.$route?.query?.filter?.regionId) {
        localRegionId = Number(this.$route?.query?.filter?.regionId)
      }
      // если regionId найден - получаем инфо о регионе
      if (localRegionId) {
        const storeRegion = this.$store.getters['region/getListItemById'](localRegionId)
        if (storeRegion) {
          currentRegion = {
            id: storeRegion.id,
            fullLabel: this.$store.getters['region/getRegionFullLabel'](storeRegion)
          }
        }
      }
      return currentRegion
    }
  }
}
</script>

<style scoped>

</style>
