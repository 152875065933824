<template>
  <div>
    <v-data-table
      v-if="value.length"
      dense
      max-height="333px"
      hide-default-footer
      :headers="headers"
      :items="value"
      :footer-props="{
            'items-per-page-options': [25, 50, 100]
          }"
      class="scroll-header-tables"
    >
      <template v-slot:item="{item, index}">
        <FellingTicketNasajdeniyaItem
          :item="value[index]"
          :headers="headers"
        />
      </template>
    </v-data-table>
    <BaseAlert
      v-else
      type="error"
      :text="'Не выбраны насаждения'"
    />
  </div>
</template>

<script>

import BaseAlert from '@/components/base/UI/BaseAlert'
import FellingTicketNasajdeniyaItem
  from '@/components/views/account/fellingTicket/detail/detailView/FellingTicketNasajdeniyaItem'

export default {
  name: 'NasajdeniyaForReportList',
  components: { FellingTicketNasajdeniyaItem, BaseAlert },
  props: {
    value: Array,
    headers: Array
  }
}
</script>

<style scoped>
  /deep/ .v-data-table__wrapper {
    max-height: calc(100vh - 173px);
  }
</style>
