export default {
  methods: {
    async handleCLick (fileName) {
      const response = await this.axios({
        url: '/file/' + fileName,
        method: 'GET',
        responseType: 'blob'
      })
      if (response) {
        let fileLink = document.createElement('a')

        fileLink.href = window.URL.createObjectURL(new Blob([response.data]))
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)

        fileLink.click()
      }
    }
  }
}
