<template>
  <tr>
    <td
      v-for="col in headers"
      :key="col.value"
    >{{ getFormattedValue(col) }}
    </td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import { confirmTypes } from '@/config/common'

export default {
  mixins: [formatters],
  name: 'FellingTicketNasajdeniyaItem',
  props: {
    headers: Array,
    item: Object
  },
  methods: {
    getFormattedValue (col) {
      let formattedValue = this.item.nasajdeniya.properties[col.value]
      if (col.type === 'option') {
        if (col.optionCategoryId) {
          formattedValue = this.$store.getters['handbook/getCategoryValue'](col.optionCategoryId, formattedValue)
        }
      } else if (col.type === 'multipleOption') {
        let valueArray = ''
        if (Array.isArray(formattedValue)) {
          formattedValue.forEach(item => valueArray += this.$store.getters['handbook/getCategoryValue'](col.optionCategoryId, item) + ' ')
        }
        formattedValue = valueArray
      } else if (col.type === 'confirm') {
        formattedValue = confirmTypes.find(item => item.value === formattedValue).label
      }

      return formattedValue
    }
  }
}
</script>

<style scoped>

</style>
