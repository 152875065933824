<template>
  <div class="multi-input pa-3 mb-3">
    <div class="subtitle-2 mb-3"> {{label}} </div>
    <div
      class="d-flex"
      v-for="(item, index) in localValue"
      :key="index"
    >
      <v-text-field
        dense
        outlined
        :disabled="disabled"
        :label="'Приложение ' + (index+1)"
        :value="item"
        @input="handleValueInputChange(index, $event)"
      ></v-text-field>
      <v-btn
        icon
        small
        :disabled="disabled"
        @click="handleRemoveValue(index)"
      >
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </div>
    <div class="d-flex justify-center">
    <v-btn
      small
      :disabled="disabled"
      @click="handleAddValue"
    >Добавить приложение
      <v-icon small>mdi-plus</v-icon>
    </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseMultiInput',
  props: {
    value: Array,
    label: String,
    disabled: Boolean
  },
  data () {
    return {
      localValue: this.value
    }
  },
  watch: {
    value: {
      handler () {
        this.localValue = this.value
      },
      immediate: true
    }
  },
  methods: {
    newValue () {
      return ''
    },
    handleAddValue () {
      this.localValue.push(this.newValue())
    },
    handleRemoveValue (index) {
      this.localValue.splice(index, 1)
    },
    handleValueInputChange (index, data) {
      this.localValue[index] = data
      this.$emit('input', this.localValue)
    }
  }
}
</script>

<style scoped>
.multi-input {
  border: 1px solid #e2e2e2;
  border-radius: 12px;
}
</style>
