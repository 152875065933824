<template>
  <BaseRelatedTable
    :value="dialog"
    @input="handleDone"
    :title="'Выберите список насаждений'"
    :card-title="'Насаждения для паспорта ' + passport.properties.object_name"
  >
    <template #table>
      <BaseList
        :passport-entities="passport.properties.passportEntities"
        v-model="selectedItems"
        :headers="headers"
        :entity-id="entityId"
        controller="object"
        :can-create="false"
        :query="{filter}"
        :show-select="true"
        item-key="properties.id"
        :class-table="classTable || 'felling-table'"
      >
        <template #item="{item, isSelected}">
          <ListObjectItem
            :is-selected="isSelected"
            selectable
            :item="item"
            :can-click="false"
            @input="handleAndEmitSelected($event)"
            :headers="headers"
            :verification-scenario="'origin'"
          />
        </template>
      </BaseList>
    </template>
  </BaseRelatedTable>
</template>

<script>
import BaseRelatedTable from '@/components/base/BaseRelatedTable'
import tableSelectedItems from '@/components/mixins/tableSelectedItems'
import BaseRegionSelect from '@/components/base/forms/BaseRegionSelect'
import ListObjectItem
  from '@/components/views/account/passport/detail/views/passportDashboard/passportObjectsTable/ListObjectItem'
import BaseList from '@/components/base/BaseList'

export default {
  mixins: [tableSelectedItems],
  name: 'NasajdeniyaList',
  components: { BaseRegionSelect, BaseRelatedTable, ListObjectItem, BaseList },
  props: {
    dialog: Boolean,
    value: Array,
    passport: Object,
    headers: Array,
    entityId: Number,
    classTable: String
  },
  computed: {
    controllerName () {
      return 'object'
    },
    filter () {
      return {
        passport_id: this.passport.properties.id,
        eav_entity_id: this.entityId,
        verificationScenario: 'origin',
        ...this.$route.query.filter
      }
    }
  },
  methods: {
    handleDone () {
      this.$emit('update:dialog', false)
    },
    formatSelected (nasajdeniya) {
      return { id: null, nasajdeniya }
    },
    transformValue (valueItem) {
      return valueItem.nasajdeniya
    },
    findValueItem: item => data => item.properties.id === data.nasajdeniya.properties.id
  }
}
</script>

<style scoped>
  /deep/ .felling-table .v-data-table .v-data-table__wrapper {
    max-height: calc(100vh - 210px) !important;
  }
</style>
