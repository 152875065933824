export default {
  props: {
    value: Object
  },
  data () {
    return {
      detail: {},
      passportLocal: {}
    }
  },
  computed: {
    passportList () {
      return this.$store.getters['serviceData/get']('passport.list')
    },
    isSave () {
      return !!this.detail.id;

    }
  },
  methods: {
    async passport () {
      if (this.detail.passport) {
        this.passportLocal = this.detail.passport
      } else if (this.detail.passport_id) {
        let response = await this.$store.dispatch('server/get', {
          url: 'passport' + '/detail/' + this.detail.passport_id
        })
        if (response) {
          this.passportLocal = response
        }
      }
      return true
    }
  },
  watch: {
    value: {
      handler () {
        this.detail = this.value
      },
      deep: true,
      immediate: true
    },
    'detail.passport_id': {
      handler () {
        return this.passport()
      },
      deep: true,
      immediate: true
    }
  }
}