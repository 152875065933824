<template>
  <v-card
    outlined
    v-if="passportList"
    class="elevation-1 rounded-0"
  >
    <BaseAlert
      v-if="!isSave"
      text="Внимание! После сохранения отредактировать данный документ невозможно"
      type="info"
    />
    <v-card-title class="d-flex justify-space-between">
      {{ detail.id ? 'Заявление на выдачу ПБ #' + detail.id : 'Заявление на выдачу ПБ' }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <BasePassportSelect
            :disabled="isSave"
            v-model="detail.passport_id"
            :passport-list="passportList"
            class="mb-5"
          />
          <BaseDialogTableSelect
            :disabled="isSave"
            v-if="passportLocal.properties"
            v-model="detail.nasajdeniya"
            :passport="passportLocal"
            class="mb-5"
          />
          <BaseDatePicker
            :disabled="isSave"
            v-model="detail.declaration_date"
            label="Дата составления"
          />
          <v-text-field
            :disabled="isSave"
            v-model="detail.declarant_full_name"
            color="secondary"
            label="ФИО заявителя"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.declarant_address"
            color="secondary"
            label="Адрес заявителя"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.phone"
            color="secondary"
            label="Телефон заявителя"
            dense
            counter
            maxlength="11"
            outlined
          ></v-text-field>
          <v-text-field
            :disabled="isSave"
            v-model="detail.object_address"
            color="secondary"
            label="Адрес объекта"
            dense
            outlined
          ></v-text-field>
          <v-textarea
            :disabled="isSave"
            v-model="detail.cause"
            color="secondary"
            label="Причина"
            dense
            outlined
          ></v-textarea>
          <BaseMultiInput
            :disabled="isSave"
            label="Приложение"
            v-model="detail.application"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <div class="d-flex justify-center">
      <v-btn
        class="ml-2"
        large
        color="info"
        v-if="detail.id && !detail.fellingTicketId"
        :to="{name: 'fellingTicketDetail', params: {id: 'create'}, query: {request_felling_ticket_id : detail.id}}"
      >
        <v-icon class="mr-2">mdi-text-box-check</v-icon>
        Создать порубочный билет
      </v-btn>
      <v-btn
        v-if="detail.fellingTicketId"
        class="ml-2"
        large
        color="info"
        :to="{name: 'fellingTicketDetail', params: {id: detail.fellingTicketId}}"
      >
        <v-icon class="mr-2">mdi-arrow-top-right</v-icon>
        Перейти в порубочный билет
      </v-btn>
      <v-btn
        large
        class="ml-2"
        color="info"
        v-if="detail.file"
        @click.prevent="handleCLick(detail.file.path + detail.file.file_name)"
      >
        <v-icon class="mr-2">mdi-file-document</v-icon>
        Скачать {{ detail.file.name }}
      </v-btn>
      <v-btn
        large
        class="ml-2"
        color="info"
        v-if="detail.fellingTicket && detail.fellingTicket.file"
        @click.prevent="handleCLick(detail.file.path + detail.fellingTicket.file.file_name)"
      >
        <v-icon class="mr-2">mdi-file-document</v-icon>
        Скачать {{ detail.fellingTicket.file.name }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import BaseDatePicker from '@/components/base/BaseDatePicker'
import BaseMultiInput from '@/components/base/forms/BaseMultiInput'
import downloadFile from '@/components/mixins/downloadFile'
import BasePassportSelect from '@/components/base/forms/BasePassportSelect'
import nasajdeniyaSelect from '@/components/mixins/nasajdeniyaSelect'
import BaseDialogTableSelect from '@/components/base/BaseDialogTableSelect'
import BaseAlert from '@/components/base/UI/BaseAlert'

export default {
  mixins: [formatters, downloadFile, nasajdeniyaSelect],
  components: { BaseAlert, BasePassportSelect, BaseDatePicker, BaseMultiInput, BaseDialogTableSelect },
  name: 'DetailView'
}
</script>

<style scoped>

</style>
